<template>
  <b-row class="match-height">
    <b-col
      lg="6"
      md="6"
    >
      <card-advance-congratulation :data="congratulations" />
    </b-col>
    <b-col
      lg="3"
      md="6"
    >
      <card-statistic-profit-chart :data="cardstatisticprofitchart" :key="chartKey"/>
    </b-col>
    <b-col
      lg="3"
      md="6"
    >
      <card-advance-medal :data="cardadvancemedal" :key="chartKey"/>
    </b-col>
    <b-col
      lg="12"
      md="6"
    >
      <apex-line-chart :data="apexlinechart" :key="chartKey"/>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ApexLineChart from './ApexLineChart.vue'
import CardAdvanceCongratulation from './CardAdvanceCongratulation.vue'
import CardAdvanceMedal from './CardAdvanceMedal.vue'
import CardStatisticProfitChart from './CardStatisticProfitChart.vue'
import { $themeColors } from '@themeConfig'
import store from '@/store/index'
export default {
  components: {
    BRow,
    BCol,
    ApexLineChart,
    CardAdvanceCongratulation,
    CardAdvanceMedal,
    CardStatisticProfitChart
  },
  data() {
    return {
      chartKey: 0,
      congratulations:{
        name:'',
        saleToday:''
      },
      cardadvancemedal:{
        num:""
      },
      cardstatisticprofitchart:{
        series: [
          {
            name: '诊金',
            data: [],
          },
        ],
      },
      apexlinechart: {
        series: [
          {
            data: [600, 200, 220, 180, 270, 250, 70, 90, 200, 150, 160, 100, 150, 100, 50],
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [$themeColors.warning],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>'}${
                data.series[data.seriesIndex][data.dataPointIndex]
              }单</span></div>`
            },
          },
          xaxis: {
            categories: [
              '7/12',
              '8/12',
              '9/12',
              '10/12',
              '11/12',
              '12/12',
              '13/12',
              '14/12',
              '15/12',
              '16/12',
              '17/12',
              '18/12',
              '19/12',
              '2023-09-06',
              '2023-09-07',
            ],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    }
  },
  methods: {
    dailysexdaytotal(){
      this.$api.meetorder.dailysexdaytotal().then((res) => {
        this.cardstatisticprofitchart.series[0].data = res.data.pricetotals
        this.cardadvancemedal.num = res.data.usertotals
        this.apexlinechart.chartOptions.xaxis.categories = res.data.arrday
        this.apexlinechart.series[0].data = res.data.orderCounts
        this.chartKey++;
      });
      
    },
  },
  created() {
    // data
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.congratulations.name = userData.fullName
    this.dailysexdaytotal()
  },
  mounted(){
    store.dispatch('doctor/fetchDoctorlist')
  }
}
</script>
